import React, { FC } from 'react'
import { Menu } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import { LoginButton, LogoutButton } from './AuthButtons'
import { NavMenu } from './types'

export const RightNavMenu: FC<NavMenu> = ({ theme, mode }) => {
  const { isAuthenticated } = useAuth0()

  return (
    <Menu theme={theme} mode={mode}>
      {isAuthenticated ? (
        <Menu.Item>
          <LogoutButton />
        </Menu.Item>
      ) : (
        <Menu.Item>
          <LoginButton />
        </Menu.Item>
      )}
    </Menu>
  )
}
