import React from 'react'
import { Route, Switch } from 'react-router-dom'
import logo from './Civilization_VI.svg'
import 'antd/dist/antd.css'
import { Layout } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import { LeftNavMenu, RightNavMenu } from './menu'
import { CivLists, Profile, Standings } from './pages'

const { Header, Content, Footer } = Layout

function App() {
  const { isAuthenticated } = useAuth0()
  return (
    <Layout>
      <Header
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <LeftNavMenu theme="dark" mode="horizontal" />
        <RightNavMenu theme="dark" mode="horizontal" />
      </Header>
      <Content
        className="site-layout"
        style={{ padding: '0 50px', marginTop: 64 }}
      >
        <div
          className="site-layout-background"
          style={{ padding: 24, height: 'calc(100vh - 134px)' }}
        >
          <Switch>
            {isAuthenticated && (
              <Route
                path="/profile"
                render={(props) => {
                  return <Profile />
                }}
              />
            )}
            {isAuthenticated && (
              <Route
                path="/standings"
                render={(props) => {
                  return <Standings />
                }}
              />
            )}
            {isAuthenticated && (
              <Route
                path="/civ-lists"
                render={(props) => {
                  return <CivLists />
                }}
              />
            )}
            <Route
              path="/"
              render={() => (
                <header>
                  <img src={logo} alt="logo" />
                </header>
              )}
            />
          </Switch>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>© 2020 Shaun McNamee</Footer>
    </Layout>
  )
}

export default App
